var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vx-card',{staticClass:"overflow-hidden mb-2",attrs:{"title":_vm.lang.surveyIntegrations.channels.whatsapp.settings.confirmation.title[
      _vm.languageSelected
    ],"subtitle":_vm.lang.surveyIntegrations.channels.whatsapp.settings.confirmation.subtitle[
      _vm.languageSelected
    ]}},[_c('div',{staticClass:"vx-row mb-4"},[_c('vs-col',{attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[_c('span',{staticClass:"custom-label"},[_vm._v("\n        "+_vm._s(((_vm.lang.surveyIntegrations.channels.whatsapp.settings.confirmation
              .form.switch[_vm.languageSelected]) + " " + (_vm.confirmationR ? '*' : '')))+"\n      ")]),_vm._v(" "),_c('vx-slider',{attrs:{"color":"danger"},model:{value:(_vm.confirmationR),callback:function ($$v) {_vm.confirmationR=$$v},expression:"confirmationR"}})],1)],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.integration || (_vm.integration && _vm.confirmationR)),expression:"!integration || (integration && confirmationR)"}],staticClass:"vx-row mb-base"},[(_vm.firstMessage != undefined)?_c('vs-col',{attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-2",attrs:{"label":((_vm.lang.surveyIntegrations.channels.whatsapp.settings.confirmation
              .form.firstMessage.label[_vm.languageSelected]) + " " + (_vm.confirmationR ? '*' : '')),"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.confirmation.form
            .firstMessage.placeholder[_vm.languageSelected],"disabled":!_vm.confirmationR},model:{value:(_vm.firstMessageR),callback:function ($$v) {_vm.firstMessageR=$$v},expression:"firstMessageR"}})],1):_vm._e(),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-2",attrs:{"label":((_vm.lang.surveyIntegrations.channels.whatsapp.settings.confirmation
              .form.rejectedMsg.label[_vm.languageSelected]) + " " + (_vm.confirmationR ? '*' : '')),"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.confirmation.form
            .rejectedMsg.placeholder[_vm.languageSelected],"disabled":!_vm.confirmationR},model:{value:(_vm.rejectedMessageR),callback:function ($$v) {_vm.rejectedMessageR=$$v},expression:"rejectedMessageR"}})],1),_vm._v(" "),_c('vs-col',{attrs:{"vs-lg":'12',"vs-xs":'12',"vs-sm":'12'}},[_c('vs-input',{staticClass:"inputx w-full mt-2",attrs:{"label":((_vm.lang.surveyIntegrations.channels.whatsapp.settings.confirmation
              .form.retryMsg.label[_vm.languageSelected]) + " " + (_vm.confirmationR ? '*' : '')),"placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.confirmation.form
            .retryMsg.placeholder[_vm.languageSelected],"disabled":!_vm.confirmationR},model:{value:(_vm.resendMessageR),callback:function ($$v) {_vm.resendMessageR=$$v},expression:"resendMessageR"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }