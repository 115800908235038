<template>
  <vx-card
    :title="
      lang.surveyIntegrations.channels.whatsapp.settings.confirmation.title[
        languageSelected
      ]
    "
    class="overflow-hidden mb-2"
    :subtitle="
      lang.surveyIntegrations.channels.whatsapp.settings.confirmation.subtitle[
        languageSelected
      ]
    "
  >
    <div class="vx-row mb-4">
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <span class="custom-label">
          {{
            `${
              lang.surveyIntegrations.channels.whatsapp.settings.confirmation
                .form.switch[languageSelected]
            } ${confirmationR ? '*' : ''}`
          }}
        </span>
        <vx-slider color="danger" v-model="confirmationR" />
      </vs-col>
    </div>
    <div
      v-show="!integration || (integration && confirmationR)"
      class="vx-row mb-base"
    >
      <vs-col
        v-if="firstMessage != undefined"
        :vs-lg="'12'"
        :vs-xs="'12'"
        :vs-sm="'12'"
      >
        <vs-input
          class="inputx w-full mt-2"
          :label="
            `${
              lang.surveyIntegrations.channels.whatsapp.settings.confirmation
                .form.firstMessage.label[languageSelected]
            } ${confirmationR ? '*' : ''}`
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.confirmation.form
              .firstMessage.placeholder[languageSelected]
          "
          v-model="firstMessageR"
          :disabled="!confirmationR"
        />
      </vs-col>
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
          class="inputx w-full mt-2"
          :label="
            `${
              lang.surveyIntegrations.channels.whatsapp.settings.confirmation
                .form.rejectedMsg.label[languageSelected]
            } ${confirmationR ? '*' : ''}`
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.confirmation.form
              .rejectedMsg.placeholder[languageSelected]
          "
          v-model="rejectedMessageR"
          :disabled="!confirmationR"
        />
      </vs-col>
      <vs-col :vs-lg="'12'" :vs-xs="'12'" :vs-sm="'12'">
        <vs-input
          class="inputx w-full mt-2"
          :label="
            `${
              lang.surveyIntegrations.channels.whatsapp.settings.confirmation
                .form.retryMsg.label[languageSelected]
            } ${confirmationR ? '*' : ''}`
          "
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.confirmation.form
              .retryMsg.placeholder[languageSelected]
          "
          v-model="resendMessageR"
          :disabled="!confirmationR"
        />
      </vs-col>
    </div>
  </vx-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Confirmation',
  props: {
    integration: {
      type: Boolean,
      default: false
    },
    confirmation: {
      type: Boolean,
      required: true
    },
    firstMessage: String,
    rejectedMessage: String,
    resendMessage: String
  },
  data() {
    return {
      firstMessageR: '',
      resendMessageR: '',
      rejectedMessageR: '',
      confirmationR: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  mounted() {
    console.log('FIRST MESSAGE::', this.firstMessage)
    this.firstMessageR = `${this.firstMessage}`
    this.confirmationR = this.confirmation
    this.rejectedMessageR = `${this.rejectedMessage}`
    this.resendMessageR = `${this.resendMessage}`
  },
  methods: {},
  watch: {
    confirmationR(newVal) {
      this.$emit('update:confirmation', newVal)
    },
    rejectedMessageR(newVal) {
      this.$emit('update:rejectedMessage', newVal)
    },
    resendMessageR(newVal) {
      this.$emit('update:resendMessage', newVal)
    },
    firstMessageR(newVal) {
      this.$emit('update:firstMessage', newVal)
    }
  }
}
</script>
